import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db, auth } from "./firebaseConfig";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import AppNavbar from "./Navbar";
import "./SavedProperties.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const SavedProperties = () => {
  const [savedProperties, setSavedProperties] = useState([]);
  const currentUser = auth.currentUser;
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchSavedProperties = async () => {
      try {
        if (!currentUser) {
          // Ensure currentUser is not null before trying to fetch properties
          return;
        }

        const userDocRef = doc(db, "users", currentUser.uid);
        const savedPropertiesCollectionRef = collection(
          userDocRef,
          "saved_properties"
        );

        const querySnapshot = await getDocs(savedPropertiesCollectionRef);

        const properties = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSavedProperties(properties);
      } catch (error) {
        console.error("Error fetching saved properties:", error.message);
      }
    };

    fetchSavedProperties(); // No need to check currentUser again, it's handled inside the function
  }, [currentUser]);

  const handleDeleteProperty = async (propertyId) => {
    try {
      const userDocRef = doc(db, "users", currentUser.uid);
      const savedPropertiesCollectionRef = collection(
        userDocRef,
        "saved_properties"
      );

      // Remove the property with the given ID from the collection
      await deleteDoc(doc(savedPropertiesCollectionRef, propertyId));

      // Update the state to reflect the deletion
      setSavedProperties((prevProperties) =>
        prevProperties.filter((property) => property.id !== propertyId)
      );
    } catch (error) {
      console.error("Error deleting property:", error.message);
    }
  };
  
  // Function to handle navigation
  const handlePropertyClick = (property) => {
    navigate(`/`, {
      state: {
        propertyData: property,
      },
    });
  };

  const houseTypeMap = {
    1: "Single Family",
    2: "Two Family",
    3: "Three Family",
    4: "Four Family",
  }

  return (
    <div>
      <AppNavbar />
      <div className="saved-properties-container">
        <div className="saved-properties-info">
          <h1 className="saved-properties-title">Saved Properties</h1>
          {savedProperties.length === 0 ? (
            <p>No saved properties found.</p>
          ) : (
            <ul className="saved-properties-list">
              {savedProperties.map((property) => (
                <li key={property.id} className="saved-property-item">
                  {/* Use onClick to handle the navigation */}
                  <div
                    onClick={() => handlePropertyClick(property)}
                    style={{ cursor: "pointer" }}
                  >
                    {`${property.street}, ${property.city}, ${property.state_code} ${property.zipcode}`}
                    <br />
                    {`House Type: ${houseTypeMap[property.factor]  || "Single Family"}`}
                    <br />
                    {`Bedrooms: ${property.num_bedrooms}, Bathrooms: ${property.num_bathrooms}`}
                    <br />
                    {`County: ${property.county_name}, Purchase Price: ${property.purchase_price}`}
                  </div>
                  <button
                className="delete-property-button"
                onClick={() => handleDeleteProperty(property.id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavedProperties;
