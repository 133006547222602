import "./PropertyDetails.css";
import React, { useState, useEffect } from "react";
import LoadingSpinner from "./LoadingSpinner";
import "./LoadingSpinner.css";
import { toast } from "react-toastify"; // Import the toast component
import "react-toastify/dist/ReactToastify.css"; // Import the toast styles
import HoldPeriodTable from "./HoldPeriodTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import GoogleMap from "./GoogleMap";
import {
  doc,
  setDoc,
  getDocs,
  collection,
  addDoc,
  query,
  where,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, auth } from "./firebaseConfig";

const PropertyDetails = ({
  property,
  setProperty,
  metricDisplayNames,
  year1Metrics,
  setYear1Metrics,
  saleAssumptions,
  setSaleAssumptions, // This is the set function for salesAssumptions
  overallReturns,
  setOverallReturns,
  metricsForHoldPeriod,
  setMetricsForHoldPeriod,
  summaryData,
  setSummaryData,
  scriptLoaded,
}) => {
  const currentUser = auth.currentUser;
  const [showAdditionalMetrics, setShowAdditionalMetrics] = useState(false);
  const [showAdditionalExpenses, setShowAdditionalExpenses] = useState(false);

  const categoryMetrics = {
    Rent: ["hud_rent", "rentometer_rent"],
    "Crime Metrics": ["violent_crime_grade", "property_crime_grade"],
    "Acquisition Cost": ["purchase_price", "closing_cost", "total_acquisition"],
    Income: [
      "rent",
      "rent_annual_growth_rate",
      "other_income",
      "other_income_annual_growth_rate",
      "vacancy_rate",
    ],
    "Effective Gross Revenue": ["effective_gross_revenue"],
    Expenses: [
      "property_management",
      "property_management_annual_growth_rate",
      "insurance",
      "insurance_annual_growth_rate",
      "property_taxes",
      "property_taxes_annual_growth_rate",
      "maintenance",
      "property_maintenance_annual_growth_rate",
      "total_expenses",
    ],
    "Net Operating Income": ["net_operating_income"],
    "Loan Assumptions": [
      "loan_to_value",
      "loan_amount",
      "interest_rate",
      "loan_term",
      "financing_fees",
      "down_payment",
      "total_cost_to_close",
      "monthly_loan_payment",
    ],
    "Unlevered Cash Flows": [
      "monthly_unlevered_cash_flow",
      "yearly_unlevered_cash_flow",
    ],
    "Levered Cash Flows": [
      "monthly_levered_cash_flow",
      "yearly_levered_cash_flow",
    ],
    Returns: ["unlevered_returns", "levered_returns"],
  };

  const salesAssumptionOrder = [
    "sale_price_appreciation",
    "annual_price_appreciation",
    "hold_period",
    "sale_month",
    "cost_to_sell",
  ];

  const overallReturnsOrder = {
    Unlevered: [
      "unlevered_equity",
      "unlevered_equity_multiple",
      "unlevered_profit",
      "unlevered_xirr",
    ],
    Levered: [
      "levered_equity",
      "levered_equity_multiple",
      "levered_profit",
      "levered_xirr",
    ],
  };

  // State to manage editable metric inputs
  const [metricInputs, setMetricInputs] = useState({
    purchase_price: year1Metrics.purchase_price,
    closing_cost: year1Metrics.closing_cost,
    rent: year1Metrics.rent,
    loan_to_value: year1Metrics.loan_to_value,
    interest_rate: year1Metrics.interest_rate,
    loan_term: year1Metrics.loan_term,
    financing_fees: year1Metrics.financing_fees,
    property_management: year1Metrics.property_management,
    insurance: year1Metrics.insurance,
    property_taxes: year1Metrics.property_taxes,
    other_income: year1Metrics.other_income,
    vacancy_rate: year1Metrics.vacancy_rate,
    rent_annual_growth_rate: year1Metrics.rent_annual_growth_rate,
    other_income_annual_growth_rate:
      year1Metrics.other_income_annual_growth_rate,
    maintenance: year1Metrics.maintenance,
    property_maintenance_annual_growth_rate:
      year1Metrics.property_maintenance_annual_growth_rate,
    property_management_annual_growth_rate:
      year1Metrics.property_management_annual_growth_rate,
    insurance_annual_growth_rate: year1Metrics.insurance_annual_growth_rate,
    property_taxes_annual_growth_rate:
      year1Metrics.property_taxes_annual_growth_rate,
    annual_price_appreciation: saleAssumptions.annual_price_appreciation,
    cost_to_sell: saleAssumptions.cost_to_sell,
    hold_period: saleAssumptions.hold_period,
  });

  const generateAddress = () => {
    // Check if property is available and contains necessary address components
    if (
      property &&
      property.street &&
      property.city &&
      property.zipcode &&
      property.state_code
    ) {
      const { street, city, zipcode, state_code } = property;
      // Concatenate address components into a single string
      const address = `${street}, ${city}, ${state_code} ${zipcode}`;
      //console.log('Geocoding Address:', address);
      return address;
    } else {
      //console.error("Invalid property object:", property);
      return null; // Return null when address cannot be generated
    }
  };

  // Call the generateAddress function to get the address
  const address = generateAddress();

  // Update metricInputs whenever the year1Metrics prop changes
  useEffect(() => {
    if (year1Metrics) {
      setMetricInputs({
        purchase_price: year1Metrics.purchase_price,
        closing_cost: year1Metrics.closing_cost,
        rent: year1Metrics.rent,
        loan_to_value: year1Metrics.loan_to_value,
        interest_rate: year1Metrics.interest_rate,
        loan_term: year1Metrics.loan_term,
        financing_fees: year1Metrics.financing_fees,
        property_management: year1Metrics.property_management,
        insurance: year1Metrics.insurance,
        property_taxes: year1Metrics.property_taxes,
        other_income: year1Metrics.other_income,
        vacancy_rate: year1Metrics.vacancy_rate,
        rent_annual_growth_rate: year1Metrics.rent_annual_growth_rate,
        other_income_annual_growth_rate:
          year1Metrics.other_income_annual_growth_rate,
        maintenance: year1Metrics.maintenance,
        property_maintenance_annual_growth_rate:
          year1Metrics.property_maintenance_annual_growth_rate,
        property_management_annual_growth_rate:
          year1Metrics.property_management_annual_growth_rate,
        insurance_annual_growth_rate: year1Metrics.insurance_annual_growth_rate,
        property_taxes_annual_growth_rate:
          year1Metrics.property_taxes_annual_growth_rate,
        annual_price_appreciation: saleAssumptions.annual_price_appreciation,
        cost_to_sell: saleAssumptions.cost_to_sell,
        hold_period: saleAssumptions.hold_period,
      });
    }
  }, [year1Metrics, saleAssumptions]);

  if (!year1Metrics) {
    // Render the loading spinner when propertyData is not available
    return <LoadingSpinner />;
  }

  const handleUpdateMetrics = async () => {
    // Check if any required input is empty
    const hasEmptyInputs = Object.keys(metricInputs).some(
      (metricType) =>
        metricInputs.hasOwnProperty(metricType) &&
        metricInputs[metricType].trim() === ""
    );

    if (hasEmptyInputs) {
      // Show an error message or style the inputs to indicate the error
      toast.error("Please fill in all metric fields.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
      });
      return;
    }
    try {
      // Construct the updated property object with metrics
      const updatedProperty = {
        ...property,
        metrics: {
          ...property.metrics,
          purchase_price: metricInputs.purchase_price,
          closing_cost: metricInputs.closing_cost,
          rent: metricInputs.rent,
          loan_to_value: metricInputs.loan_to_value,
          interest_rate: metricInputs.interest_rate,
          loan_term: metricInputs.loan_term,
          financing_fees: metricInputs.financing_fees,
          loan_processing_fees: metricInputs.loan_processing_fees,
          property_management: metricInputs.property_management,
          insurance: metricInputs.insurance,
          property_taxes: metricInputs.property_taxes,
          other_income: metricInputs.other_income,
          vacancy_rate: metricInputs.vacancy_rate,
          rent_annual_growth_rate: metricInputs.rent_annual_growth_rate,
          other_income_annual_growth_rate:
            metricInputs.other_income_annual_growth_rate,
          maintenance: metricInputs.maintenance,
          property_maintenance_annual_growth_rate:
            metricInputs.property_maintenance_annual_growth_rate,
          property_management_annual_growth_rate:
            metricInputs.property_management_annual_growth_rate,
          insurance_annual_growth_rate:
            metricInputs.insurance_annual_growth_rate,
          property_taxes_annual_growth_rate:
            metricInputs.property_taxes_annual_growth_rate,
          annual_price_appreciation: metricInputs.annual_price_appreciation, // Add sale-related metrics
          cost_to_sell: metricInputs.cost_to_sell,
          hold_period: metricInputs.hold_period,
        },
      };

      //console.log("Updated Property Object from frontend:", updatedProperty);

      // Send the updated property data to the backend
      const response = await fetch(
        "https://call-flask-route-lon4yge32a-uc.a.run.app/update_metrics",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ property: updatedProperty }),
        }
      );

      // Parse the JSON response from the backend
      const data = await response.json();

      //console.log("Updated Property Data from Backend:", data);

      // Update the property state with the updated metrics and purchase price
      setProperty(data.property);
      //console.log("set Updated property data:", data.property);
      setYear1Metrics(data.year_1_metrics);
      //console.log("set Updated year 1 data:", data.year_1_metrics);
      // Update the state with the updated Sales Assumptions and Overall Returns
      setSaleAssumptions(data.sale_assumptions);
      setOverallReturns(data.overall_returns);

      // 10 Year table
      setMetricsForHoldPeriod(data.metrics_for_hold_period);

      // Investment Summary
      setSummaryData(data.summary);

      const userDocRef = doc(db, "users", currentUser.uid);

      // Check if the 'saved_properties' collection exists
      const savedPropertiesCollectionRef = collection(userDocRef, "saved_properties");
      const savedPropertiesSnapshot = await getDocs(savedPropertiesCollectionRef);

      // If the collection doesn't exist,
      if (!savedPropertiesSnapshot.empty) {
        // Check if the property is already saved
        const existingPropertyQuery = query(
          savedPropertiesCollectionRef,
          where("street", "==", property.street), // Add other conditions as needed
          where("city", "==", property.city),
          where("state_code", "==", property.state_code),
          where("county_name", "==", property.county_name),
          // Add more conditions if needed
        );

        const existingPropertySnapshot = await getDocs(existingPropertyQuery);

        if (!existingPropertySnapshot.empty) {
          const savedPropertyDocRef = existingPropertySnapshot.docs[0].ref;
          await updateDoc(savedPropertyDocRef, {
            ...data.property,
            year_1_metrics: data.year_1_metrics,
            sale_assumptions: data.sale_assumptions,
            overall_returns: data.overall_returns,
            metrics_for_hold_period: data.metrics_for_hold_period,
            summary: data.summary,
            metric_display_names: data.metric_display_names,
            savedAt: serverTimestamp(),
          });
          //console.log("Updated property in the database");
        }
      }

      // Show a success notification
      toast.success("Metrics updated successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
      //console.log("Updated property data:", data);
    } catch (error) {
      // Handle error if the request fails
      //console.error("Error creating property:", error);

      // Show an error notification with the error message
      // Show an error notification with the error message
      let errorMessage =
        "An error occurred while updating property metrics. Please try again later.";
      if (error.message) {
        errorMessage = error.message; // Use the specific error message if available
      }

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
      });
    }
  };

  const metricTooltips = {
    closing_cost:
      "Estimated costs to close the real estate transaction, including fees and expenses.",
    down_payment:
      "The initial payment made when purchasing a property. It is a percentage of the purchase price.",
    hud_rent:
      "The maximum amount that can be covered by the Section 8 Housing Choice Voucher Program.",
    insurance: "Monthly fee paid for homeowners insurance.",
    interest_rate:
      "Annual interest rate on the loan used to purchase the property.",
    monthly_levered_cash_flow:
      "Monthly cash flow after all expenses, including mortgage payments and operating costs.",
    levered_returns: "Returns on investment after financing costs.",
    loan_amount: "Total amount borrowed from a lender.",
    financing_fees:
      "Fees paid by borrower to banker, lawyers and parties involved in arranging the financing. ",
    loan_term: "Number of years the loan will be repaid over.",
    loan_to_value: "Ratio of the loan amount to the property value.",
    monthly_loan_payment:
      "The fixed monthly payment paid by borrower to pay off loan. ",
    net_operating_income:
      "This measures how profitable the property is before considering financing and business taxes. It is calculated by  subtracting expenses from revenue.",
    property_crime_grade:
      "Grade indicating the level of property crime in the area.",
    property_management: "Monthly fee paid to third-party property manager.",
    property_taxes:
      "Tax paid on property owned by individua or other legal entity which is based on the value of the home.",
    purchase_price: "The cost of the property",
    rent: "Tenant's regular payment to landlord for use of property ",
    rentometer_rent: "The average rent for this unit type in this area. ",
    total_acquisition: "Total costs associated with acquiring the property.",
    total_cost_to_close:
      "Total costs to complete the real estate transaction, including closing costs.",
    total_expenses: "Total operating expenses for the property.",
    monthly_unlevered_cash_flow:
      "Monthly cash flow before considering financing costs.",
    unlevered_returns:
      "Returns on investment before considering financing costs.",
    violent_crime_grade:
      "Grade indicating the level of violent crime in the area.",
    vacancy_rate: "Percentage of time the property is expected to be vacant.",
    effective_gross_revenue:
      "This is the true positive cashflow that a property could generate. It take the sum of all revenues and deducts vacancy.",
    other_income:
      "Income received beyond rent. This includes profits from pet rent, storage fee, internet, parking, utility reimbursement etc",
    rent_annual_growth_rate: "Expected annual growth rate for rent income.",
    other_income_annual_growth_rate:
      "Expected annual growth rate for other income sources.",
    property_management_annual_growth_rate:
      "Expected annual growth rate for property management costs.",
    insurance_annual_growth_rate:
      "Expected annual growth rate for insurance costs.",
    property_taxes_annual_growth_rate:
      "Expected annual growth rate for property taxes.",
    property_maintenance_annual_growth_rate:
      "Expected annual growth rate for property maintenance costs.",
    sale_price_appreciation:
      "The estimated sale price based on the annual home price appreciation and hold period. ",
    annual_price_appreciation:
      "Expected annual percentage increase in the property value.",
    hold_period: "Number of years you own the property for.",
    sale_month: "Month in which the property is expected to be sold.",
    cost_to_sell: "Total costs associated with selling the property.",
    loan_payoff:
      "Amount paid to satisfy the loan and completely pay off the debt.",
    yearly_unlevered_cash_flow:
      "Yearly cash flow before considering financing costs.",
    yearly_levered_cash_flow:
      "Yearly cash flow after considering financing costs.",
    maintenance: "Cost incurred to maintain asset. ",
    unlevered_xirr:
      "Annual rate of growth that an investment is expected to generate.",
    unlevered_equity: "Unlevered equity value for the property investment. ",
    unlevered_equity_multiple:
      "This is how much money you make as a fraction of the total equity invested. It is calculated by dividing total cash received by total equity invested. ",
    unlevered_profit: "Total profit generated from the unlevered investment.",
    levered_xirr: "Annual rate of growth that an investment is expected to generate.",
    levered_equity: "Levered equity value for the property investment.",
    levered_equity_multiple:
      "This is how much money you make as a fraction of the total equity invested. It is calculated by dividing total cash received by total equity invested. ",
    levered_profit: "Total profit generated from the levered investment.",
  };

  const colorMapping = {
    Green: "#00FF00", // Green color code
    "Light-Green": "#90EE90", // Light Green color code
    Yellow: "#FFFF00", // Yellow color code
    "Red Orange": "#FF4500", // Red-Orange color code
    Red: "#FF0000", // Red color code
  };

  const houseTypeMap = {
    1: "Single Family",
    2: "Two Family",
    3: "Three Family",
    4: "Four Family",
  }

  const handleSaveProperty = async () => {
    try {
      //console.log("Saving property:", property)
      const userDocRef = doc(db, "users", currentUser.uid);

      // Check if the 'saved_properties' collection exists
      const savedPropertiesCollectionRef = collection(userDocRef, "saved_properties");
      const savedPropertiesSnapshot = await getDocs(savedPropertiesCollectionRef);

      // If the collection doesn't exist, create it
      if (savedPropertiesSnapshot.empty) {
        await setDoc(userDocRef, { saved_properties: {} }, { merge: true });
      }

      // Check if the property is already saved
      const existingPropertyQuery = query(
        savedPropertiesCollectionRef,
        where("street", "==", property.street), // Add other conditions as needed
        where("city", "==", property.city),
        where("state_code", "==", property.state_code),
        where("county_name", "==", property.county_name),
        // Add more conditions if needed
      );

      const existingPropertySnapshot = await getDocs(existingPropertyQuery);

      if (!existingPropertySnapshot.empty) {
        // If the property already exists, show a message and do not save again
        toast.info("Property is already saved!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
        });
        return;
      }

      // Create a new document in the 'saved_properties' collection
      const newPropertyDocRef = await addDoc(savedPropertiesCollectionRef, {
        // Add the entire property object and any additional data you want to save
        ...property,
        year_1_metrics: year1Metrics,
        sale_assumptions: saleAssumptions,
        overall_returns: overallReturns,
        metrics_for_hold_period: metricsForHoldPeriod,
        summary: summaryData,
        metric_display_names: metricDisplayNames,
        savedAt: serverTimestamp(),
      });


      // Update the local state to indicate that the property is saved
      setProperty({ ...property, saved: true, id: newPropertyDocRef.id });

      // Show a success toast message
      toast.success("Property saved successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
    } catch (error) {
      // Show an error toast message
      toast.error("Error saving property. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
      //console.error("Error saving property:", error);
    }
  };


  return (
    <div className="details">
      <h1 className="results-heading">Analyzer Results</h1>
      <button className="save-button" onClick={handleSaveProperty}>
        Save Property
      </button>
      <div className="property-details">
        <div className="left-column">
          <div className="property-info">
            <h2 className="metrics-title">Property Information</h2>
            <p className="bold-text">Street: {property.street}</p>
            <p className="bold-text">City: {property.city}</p>
            <p className="bold-text">Zipcode: {property.zipcode}</p>
            <p className="bold-text">State Code: {property.state_code}</p>
            <p className="bold-text">County Name: {property.county_name}</p>
            <p className="bold-text">House Type: {houseTypeMap[property.factor] || "Single Family"}</p>
            <p className="bold-text">
              Number of Bedrooms: {property.num_bedrooms}
            </p>
            <p className="bold-text">
              Number of Bathrooms: {property.num_bathrooms}
            </p>
            <p className="bold-text">
              Purchase Price: {property.purchase_price}
            </p>
          </div>

          {/* Display metrics by category */}
          {year1Metrics && (
            <div className="metrics-area">
              <h2 className="metrics-title">Metrics</h2>
              <div className="metrics">

                {/* Rent category */}
                <div className="metrics-category" key="Rent">
                  <h3>Rent</h3>
                  {categoryMetrics.Rent.map((metricType, index) => (
                    <div className="metric" key={index}>
                      <div className="metric-label">
                        {metricDisplayNames[metricType]}
                      </div>
                      <div className="metric-value">
                        {metricInputs.hasOwnProperty(metricType) ? (
                          <input
                            type="text"
                            value={metricInputs[metricType]}
                            onChange={(e) =>
                              setMetricInputs({
                                ...metricInputs,
                                [metricType]: e.target.value,
                              })
                            }
                            required
                          />
                        ) : (
                          year1Metrics[metricType]
                        )}
                      </div>
                    </div>
                  ))}
                </div>


                {/* Crime Metrics category */}
                <div className="metrics-category" key="Crime Metrics">
                  <h3>Crime Metrics</h3>
                  {categoryMetrics["Crime Metrics"].map((metricType, index) => (
                    <div className="metric" key={index}>
                      <div className="metric-label">
                        {metricDisplayNames[metricType]}
                      </div>
                      <div className="metric-value">
                        {metricInputs.hasOwnProperty(metricType) ? (
                          <input
                            type="text"
                            value={metricInputs[metricType]}
                            onChange={(e) =>
                              setMetricInputs({
                                ...metricInputs,
                                [metricType]: e.target.value,
                              })
                            }
                            required
                          />
                        ) : (
                          year1Metrics[metricType]
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Acquisition Cost category */}
                <div className="metrics-category" key="Acquisition Cost">
                  <h3>Acquisition Cost</h3>
                  {categoryMetrics["Acquisition Cost"].map((metricType, index) => (
                    <div className="metric" key={index}>
                      <OverlayTrigger
                        key={`${metricType}-tooltip`}
                        placement="right"
                        overlay={
                          <Tooltip id={`${metricType}-tooltip`}>
                            {metricTooltips[metricType]}
                          </Tooltip>
                        }
                      >
                        <div className="metric-label">
                          {metricDisplayNames[metricType]}
                        </div>
                      </OverlayTrigger>
                      <div className="metric-value">
                        {metricInputs.hasOwnProperty(metricType) ? (
                          <input
                            type="text"
                            value={metricInputs[metricType]}
                            style={{
                              width: metricInputs[metricType]
                                ? `${metricInputs[metricType].length + 2}ch`
                                : "auto",
                            }}
                            onChange={(e) =>
                              setMetricInputs({
                                ...metricInputs,
                                [metricType]: e.target.value,
                              })
                            }
                            required
                          />
                        ) : (
                          year1Metrics[metricType]
                        )}
                      </div>
                    </div>
                  ))}
                </div>


                {/* Income category */}
                <div className="metrics-category" key="Income">
                  <h3>Income</h3>

                  {/* Display the first metric pair using the current two-column layout */}
                  <div className="two-column-metrics">
                    {categoryMetrics.Income.slice(0, 2).map((metricType, index) => (
                      <div className="metric-pair" key={index}>
                        <div className="metric">
                          <OverlayTrigger
                            key={`${metricType}-tooltip`}
                            placement="right"
                            overlay={
                              <Tooltip id={`${metricType}-tooltip`}>
                                {metricTooltips[metricType]}
                              </Tooltip>
                            }
                          >
                            <div className="metric-label">
                              {metricDisplayNames[metricType].replace(/.*\bGrowth Rate\b.*/i, "Growth Rate")}
                            </div>
                          </OverlayTrigger>
                          <div className="metric-value">
                            {metricInputs.hasOwnProperty(metricType) ? (
                              <input
                                type="text"
                                value={metricInputs[metricType]}
                                style={{
                                  width: metricInputs[metricType]
                                    ? `${metricInputs[metricType].length + 2}ch`
                                    : "auto",
                                }}
                                onChange={(e) =>
                                  setMetricInputs({
                                    ...metricInputs,
                                    [metricType]: e.target.value,
                                  })
                                }
                                required
                              />
                            ) : (
                              <span>${year1Metrics[metricType]}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Button outside the two columns */}
                  <div className="toggle-metrics">
                    <div className="button-div">
                      <button onClick={() => setShowAdditionalMetrics(!showAdditionalMetrics)}>
                        {showAdditionalMetrics ? 'Hide Additional Metrics' : 'Show Additional Metrics'}
                      </button>
                    </div>
                    {showAdditionalMetrics && (
                      <div className="two-column-metrics">
                        {/* Display additional metrics inside the two columns */}
                        {categoryMetrics.Income.slice(2).map((metricType, index) => (
                          <div className="metric-pair" key={index}>
                            <div className="metric">
                              <OverlayTrigger
                                key={`${metricType}-tooltip`}
                                placement="right"
                                overlay={
                                  <Tooltip id={`${metricType}-tooltip`}>
                                    {metricTooltips[metricType]}
                                  </Tooltip>
                                }
                              >
                                <div className="metric-label">
                                  {metricDisplayNames[metricType].replace(/.*\bGrowth Rate\b.*/i, "Growth Rate")}
                                </div>
                              </OverlayTrigger>
                              <div className="metric-value">
                                {metricInputs.hasOwnProperty(metricType) ? (
                                  <input
                                    type="text"
                                    value={metricInputs[metricType]}
                                    style={{
                                      width: metricInputs[metricType]
                                        ? `${metricInputs[metricType].length + 2}ch`
                                        : "auto",
                                    }}
                                    onChange={(e) =>
                                      setMetricInputs({
                                        ...metricInputs,
                                        [metricType]: e.target.value,
                                      })
                                    }
                                    required
                                  />
                                ) : (
                                  <span>${year1Metrics[metricType]}</span>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>


                {/* Effective Gross Revenue category */}
                <div className="metrics-category" key="Effective Gross Revenue">
                  <h3>Effective Gross Revenue</h3>
                  {categoryMetrics["Effective Gross Revenue"].map((metricType, index) => (
                    <div className="metric" key={index}>
                      <OverlayTrigger
                        key={`${metricType}-tooltip`}
                        placement="right"
                        overlay={
                          <Tooltip id={`${metricType}-tooltip`}>
                            {metricTooltips[metricType]}
                          </Tooltip>
                        }
                      >
                        <div className="metric-label">
                          {metricDisplayNames[metricType]}
                        </div>
                      </OverlayTrigger>
                      <div className="metric-value">
                        {metricInputs.hasOwnProperty(metricType) ? (
                          <input
                            type="text"
                            value={metricInputs[metricType]}
                            style={{
                              width: metricInputs[metricType]
                                ? `${metricInputs[metricType].length + 2}ch`
                                : "auto",
                            }}
                            onChange={(e) =>
                              setMetricInputs({
                                ...metricInputs,
                                [metricType]: e.target.value,
                              })
                            }
                            required
                          />
                        ) : (
                          year1Metrics[metricType]
                        )}
                      </div>
                    </div>
                  ))}
                </div>


                {/* Expenses category */}
                <div className="metrics-category" key="Expenses">
                  <h3>Expenses</h3>

                  {/* Display the first metric pair using the current two-column layout */}
                  <div className="two-column-metrics">
                    {categoryMetrics.Expenses.slice(0, 2).map((metricType, index) => (
                      <div className="metric-pair" key={index}>
                        <div className="metric">
                          <OverlayTrigger
                            key={`${metricType}-tooltip`}
                            placement="right"
                            overlay={
                              <Tooltip id={`${metricType}-tooltip`}>
                                {metricTooltips[metricType]}
                              </Tooltip>
                            }
                          >
                            <div className="metric-label">
                              {metricDisplayNames[metricType].replace(/.*\bGrowth Rate\b.*/i, "Growth Rate")}
                            </div>
                          </OverlayTrigger>
                          <div className="metric-value">
                            {metricInputs.hasOwnProperty(metricType) ? (
                              <input
                                type="text"
                                value={metricInputs[metricType]}
                                style={{
                                  width: metricInputs[metricType]
                                    ? `${metricInputs[metricType].length + 2}ch`
                                    : "auto",
                                }}
                                onChange={(e) =>
                                  setMetricInputs({
                                    ...metricInputs,
                                    [metricType]: e.target.value,
                                  })
                                }
                                required
                              />
                            ) : (
                              year1Metrics[metricType]
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Button outside the two columns */}
                  <div className="toggle-metrics">
                    <div className="button-div">
                      <button onClick={() => setShowAdditionalExpenses(!showAdditionalExpenses)}>
                        {showAdditionalExpenses ? 'Hide Additional Expenses' : 'Show Additional Expenses'}
                      </button>
                    </div>
                    {showAdditionalExpenses && (
                      <div className="two-column-metrics">
                        {/* Display additional metrics inside the two columns */}
                        {categoryMetrics.Expenses.slice(2).map((metricType, index) => (
                          <div className="metric-pair" key={index}>
                            <div className="metric">
                              <OverlayTrigger
                                key={`${metricType}-tooltip`}
                                placement="right"
                                overlay={
                                  <Tooltip id={`${metricType}-tooltip`}>
                                    {metricTooltips[metricType]}
                                  </Tooltip>
                                }
                              >
                                <div className="metric-label">
                                  {metricDisplayNames[metricType].replace(/.*\bGrowth Rate\b.*/i, "Growth Rate")}
                                </div>
                              </OverlayTrigger>
                              <div className="metric-value">
                                {metricInputs.hasOwnProperty(metricType) ? (
                                  <input
                                    type="text"
                                    value={metricInputs[metricType]}
                                    style={{
                                      width: metricInputs[metricType]
                                        ? `${metricInputs[metricType].length + 2}ch`
                                        : "auto",
                                    }}
                                    onChange={(e) =>
                                      setMetricInputs({
                                        ...metricInputs,
                                        [metricType]: e.target.value,
                                      })
                                    }
                                    required
                                  />
                                ) : (
                                  <span>${year1Metrics[metricType]}</span>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>


                {/* Net Operating Income category */}
                <div className="metrics-category" key="Net Operating Income">
                  <h3>Net Operating Income</h3>
                  {categoryMetrics["Net Operating Income"].map((metricType, index) => (
                    <div className="metric" key={index}>
                      <OverlayTrigger
                        key={`${metricType}-tooltip`}
                        placement="right"
                        overlay={
                          <Tooltip id={`${metricType}-tooltip`}>
                            {metricTooltips[metricType]}
                          </Tooltip>
                        }
                      >
                        <div className="metric-label">
                          {metricDisplayNames[metricType]}
                        </div>
                      </OverlayTrigger>
                      <div className="metric-value">
                        {metricInputs.hasOwnProperty(metricType) ? (
                          <input
                            type="text"
                            value={metricInputs[metricType]}
                            style={{
                              width: metricInputs[metricType]
                                ? `${metricInputs[metricType].length + 2}ch`
                                : "auto",
                            }}
                            onChange={(e) =>
                              setMetricInputs({
                                ...metricInputs,
                                [metricType]: e.target.value,
                              })
                            }
                            required
                          />
                        ) : (
                          year1Metrics[metricType]
                        )}
                      </div>
                    </div>
                  ))}
                </div>


                {/* Loan Assumptions category */}
                <div className="metrics-category" key="Loan Assumptions">
                  <h3>Loan Assumptions</h3>
                  {categoryMetrics["Loan Assumptions"].map((metricType, index) => (
                    <div className="metric" key={index}>
                      <OverlayTrigger
                        key={`${metricType}-tooltip`}
                        placement="right"
                        overlay={
                          <Tooltip id={`${metricType}-tooltip`}>
                            {metricTooltips[metricType]}
                          </Tooltip>
                        }
                      >
                        <div className="metric-label">
                          {metricDisplayNames[metricType]}
                        </div>
                      </OverlayTrigger>
                      <div className="metric-value">
                        {metricInputs.hasOwnProperty(metricType) ? (
                          <input
                            type="text"
                            value={metricInputs[metricType]}
                            style={{
                              width: metricInputs[metricType]
                                ? `${metricInputs[metricType].length + 2}ch`
                                : "auto",
                            }}
                            onChange={(e) =>
                              setMetricInputs({
                                ...metricInputs,
                                [metricType]: e.target.value,
                              })
                            }
                            required
                          />
                        ) : (
                          year1Metrics[metricType]
                        )}
                      </div>
                    </div>
                  ))}
                </div>


                {/* Unlevered Cash Flows category */}
                <div className="metrics-category" key="Unlevered Cash Flows">
                  <h3>Unlevered Cash Flows</h3>
                  {categoryMetrics["Unlevered Cash Flows"].map((metricType, index) => (
                    <div className="metric" key={index}>
                      <OverlayTrigger
                        key={`${metricType}-tooltip`}
                        placement="right"
                        overlay={
                          <Tooltip id={`${metricType}-tooltip`}>
                            {metricTooltips[metricType]}
                          </Tooltip>
                        }
                      >
                        <div className="metric-label">
                          {metricDisplayNames[metricType]}
                        </div>
                      </OverlayTrigger>
                      <div className="metric-value">
                        {metricInputs.hasOwnProperty(metricType) ? (
                          <input
                            type="text"
                            value={metricInputs[metricType]}
                            style={{
                              width: metricInputs[metricType]
                                ? `${metricInputs[metricType].length + 2}ch`
                                : "auto",
                            }}
                            onChange={(e) =>
                              setMetricInputs({
                                ...metricInputs,
                                [metricType]: e.target.value,
                              })
                            }
                            required
                          />
                        ) : (
                          year1Metrics[metricType]
                        )}
                      </div>
                    </div>
                  ))}
                </div>


                {/* Levered Cash Flows category */}
                <div className="metrics-category" key="Levered Cash Flows">
                  <h3>Levered Cash Flows</h3>
                  {categoryMetrics["Levered Cash Flows"].map((metricType, index) => (
                    <div className="metric" key={index}>
                      <OverlayTrigger
                        key={`${metricType}-tooltip`}
                        placement="right"
                        overlay={
                          <Tooltip id={`${metricType}-tooltip`}>
                            {metricTooltips[metricType]}
                          </Tooltip>
                        }
                      >
                        <div className="metric-label">
                          {metricDisplayNames[metricType]}
                        </div>
                      </OverlayTrigger>
                      <div className="metric-value">
                        {metricInputs.hasOwnProperty(metricType) ? (
                          <input
                            type="text"
                            value={metricInputs[metricType]}
                            style={{
                              width: metricInputs[metricType]
                                ? `${metricInputs[metricType].length + 2}ch`
                                : "auto",
                            }}
                            onChange={(e) =>
                              setMetricInputs({
                                ...metricInputs,
                                [metricType]: e.target.value,
                              })
                            }
                            required
                          />
                        ) : (
                          year1Metrics[metricType]
                        )}
                      </div>
                    </div>
                  ))}
                </div>


                {/* Returns category */}
                <div className="metrics-category" key="Returns">
                  <h3>Returns</h3>
                  {categoryMetrics.Returns.map((metricType, index) => (
                    <div className="metric" key={index}>
                      <OverlayTrigger
                        key={`${metricType}-tooltip`}
                        placement="right"
                        overlay={
                          <Tooltip id={`${metricType}-tooltip`}>
                            {metricTooltips[metricType]}
                          </Tooltip>
                        }
                      >
                        <div className="metric-label">
                          {metricDisplayNames[metricType]}
                        </div>
                      </OverlayTrigger>
                      <div className="metric-value">
                        {metricInputs.hasOwnProperty(metricType) ? (
                          <input
                            type="text"
                            value={metricInputs[metricType]}
                            style={{
                              width: metricInputs[metricType]
                                ? `${metricInputs[metricType].length + 2}ch`
                                : "auto",
                            }}
                            onChange={(e) =>
                              setMetricInputs({
                                ...metricInputs,
                                [metricType]: e.target.value,
                              })
                            }
                            required
                          />
                        ) : (
                          year1Metrics[metricType]
                        )}
                      </div>
                    </div>
                  ))}
                </div>


              </div>
            </div>
          )}

        </div>

        <div className="right-column">
          {/* Sales Assumptions */}
          <div className="metrics-area">
            <h2 className="metrics-title">Sale Assumptions</h2>
            <div className="metrics">
              {salesAssumptionOrder.map((key) => (
                <div className="metric" key={key}>
                  <OverlayTrigger
                    key={`${key}-tooltip`}
                    placement="right"
                    overlay={
                      <Tooltip id={`${key}-tooltip`}>
                        {metricTooltips[key]}
                      </Tooltip>
                    }
                  >
                    <div className="metric-label">
                      {metricDisplayNames[key]}
                    </div>
                  </OverlayTrigger>
                  <div className="metric-value">
                    {metricInputs.hasOwnProperty(key) ? (
                      <input
                        type="text"
                        value={metricInputs[key]}
                        style={{
                          width: metricInputs[key]
                            ? `${metricInputs[key].length + 2}ch`
                            : "auto",
                        }}
                        onChange={(e) =>
                          setMetricInputs({
                            ...metricInputs,
                            [key]: e.target.value,
                          })
                        }
                        required
                      />
                    ) : (
                      saleAssumptions[key]
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Overall Returns */}
          <div className="metrics-area">
            <h2 className="metrics-title">Overall Returns</h2>
            <div className="metrics">
              {Object.entries(overallReturnsOrder).map(([section, metrics]) => (
                <div className="metrics-category" key={section}>
                  <h3>{section}</h3>
                  {metrics.map((key) => (
                    <div className="metric" key={key}>
                      <OverlayTrigger
                        key={`${key}-tooltip`}
                        placement="right"
                        overlay={
                          <Tooltip id={`${key}-tooltip`}>
                            {metricTooltips[key]}
                          </Tooltip>
                        }
                      >
                        <div className="metric-label">
                          {metricDisplayNames[key]}
                        </div>
                      </OverlayTrigger>
                      <div className="metric-value">{overallReturns[key] !== null ? overallReturns[key] : 'N/A'}</div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          {/* Google Map */}
          <div className="google-map">
            <GoogleMap scriptLoaded={scriptLoaded} address={address} />
          </div>

          {/* Investment Summary */}
          <div className="metrics-area">
            <h2 className="metrics-title">SFR Analyst Ratings</h2>
            <div className="metrics">
              {summaryData && (
                <div
                  className="circle"
                  style={{
                    backgroundColor: colorMapping[summaryData.overall_color],
                  }}
                >
                  <div className="point">{summaryData.overall_points}%</div>
                </div>
              )}
              {summaryData && (
                <div className="response">{summaryData.overall_response}</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 10 Yeat table */}
      {/* Include the TenYearTable component */}
      <HoldPeriodTable
        metricsForHoldPeriod={metricsForHoldPeriod}
        holdPeriod={saleAssumptions.hold_period}
      />

      {/* Instructions */}
      <div className="instruction-text">
        <p>
          Please adjust highlighted areas to ensure accuracy for your specific
          property
        </p>
      </div>

      {/* Update Metrics Button */}
      <div className="update-button-container">
        <button onClick={handleUpdateMetrics}>Update Metrics</button>
      </div>
    </div>
  );
};

export default PropertyDetails;
