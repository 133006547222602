import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { setDoc, doc, collection, getDoc } from "firebase/firestore"; // Import Firestore functions
import { db, auth } from "./firebaseConfig"; // Import both auth and db
import { sendEmailVerification } from "firebase/auth";

import logo from "./logo.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import "./Welcome.css";
import "./LoginSignup.css";
import { useNavigate } from "react-router-dom"; // Import Link

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      if (!user) {
        console.error("User not authenticated");
        return;
      }
      //console.log("Authenticated user");
      await sendEmailVerification(user);

      // Update the user profile with first and last name
      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      });
      //console.log("Set Display name");

      // Create a reference to the 'users' collection
      const usersCollectionRef = collection(db, "users");

      // Create a document reference with the user's UID as the document ID
      const userDocRef = doc(usersCollectionRef, user.uid);

      // Set the data for the document
      await setDoc(userDocRef, {
        firstname: firstName,
        lastname: lastName,
        displayName: `${firstName} ${lastName}`,
        email: email,
        subscriptionStatus: "NOT PAID",
        freeSearches: 5,
        subscriptionExpiration: null,
        autoRenewal: false,
        isAdmin: false,
      });

      //console.log("User added to database");
      //console.log("User signed up:", user);

      toast.success("User created successfully! Please check your email for verification.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Delay the redirect to allow time for the toast to be displayed
      setTimeout(() => {
        // Redirect the user to the desired location
        navigate("/");
      }, 3000); // Adjust the delay time as needed

    } catch (error) {
      console.error("Error signing up:", error);

      if (error.code === "auth/email-already-in-use") {
        toast.error("Email is already in use. Please log in.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (error.code === "auth/weak-password") {
        toast.error("Password should be at least 6 characters.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Error signing up. Please try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // Function to handle Google sign-up
  const handleGoogleSignup = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      if (user) {
        // Check if the user exists in Firestore by their UID
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        //console.log("User:", user);
        if (!userDocSnapshot.exists()) {
          // If the user doesn't exist in Firestore, create a new document for them
          const userData = {
            firstname: user.displayName.split(" ")[0], // Extract first name from display name
            lastname: user.displayName.split(" ")[1], // Extract last name from display name
            displayName: user.displayName,
            email: user.email,
            subscriptionStatus: "NOT PAID",
            freeSearches: 5,
            subscriptionExpiration: null,
            autoRenewal: false,
            isAdmin: false,
          };

          // Set the user data in Firestore
          await setDoc(userDocRef, userData);
        }

        // Redirect the user to the desired location
        navigate("/");
      }
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  return (
    <div className="login wrapper">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <section className="container">
        <form className="form-section" onSubmit={handleSignup}>
          <div className="form-wrapper">
            <div className="header-container">
              <a href="/" className="title">
                <h1 className="h1  mb-0">
                  <span className="ana">Ana</span>lyzer
                </h1>
              </a>
            </div>

            <h2>Welcome!</h2>
            <p>Enter your credentials to create your account.</p>

            <div className="input-container">
              <div className="form-group">
                <label htmlFor="first_name">First Name</label>
                <input
                  className="input"
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="last_name">Last Name</label>
                <input
                  className="input"
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="input-container">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className="input"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  className="input"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>

            <button className="login-btn" type="submit">Sign Up</button>
            <p className="instr">
              Already have an account. Please! <a href="login">login</a>
            </p>
            <div className="or-divider">or</div>

            <button className="google-signin" onClick={handleGoogleSignup}>
              <svg
                width="40px"
                height="54px"
                viewBox="-15.84 -15.84 79.68 79.68"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <title>Google-color</title> <desc>Created with Sketch.</desc>{" "}
                  <defs> </defs>{" "}
                  <g
                    id="Icons"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    {" "}
                    <g
                      id="Color-"
                      transform="translate(-401.000000, -860.000000)"
                    >
                      {" "}
                      <g
                        id="Google"
                        transform="translate(401.000000, 860.000000)"
                      >
                        {" "}
                        <path
                          d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
                          id="Fill-1"
                          fill="#FBBC05"
                        >
                          {" "}
                        </path>{" "}
                        <path
                          d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
                          id="Fill-2"
                          fill="#EB4335"
                        >
                          {" "}
                        </path>{" "}
                        <path
                          d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
                          id="Fill-3"
                          fill="#34A853"
                        >
                          {" "}
                        </path>{" "}
                        <path
                          d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
                          id="Fill-4"
                          fill="#4285F4"
                        >
                          {" "}
                        </path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>{" "}
                </g>
              </svg>{" "}
              <span>Sign up with Google</span>
            </button>
          </div>
        </form>
      </section>
      <ToastContainer />
    </div>
  );
};

export default Signup;
