// PropertyForm.js

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropertyDetails from "./PropertyDetails"; // Add this import statement
import LoadingSpinner from "./LoadingSpinner"; // Import the LoadingSpinner component
import "./PropertyForm.css";
import { toast } from "react-toastify"; // Import the toast component
import "react-toastify/dist/ReactToastify.css"; // Import the toast styles
import { db, auth } from "./firebaseConfig";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom"; // Import Link
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';


const PropertyForm = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [countyName, setCountyName] = useState("");
  const [numBedrooms, setNumBedrooms] = useState("");
  const [numBathrooms, setNumBathrooms] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [factor, setFactor] = useState("");

  const [year1Metrics, setYear1Metrics] = useState(null);
  const [propertyData, setPropertyData] = useState(null);
  const [metric_display_names, setMetric_display_names] = useState(null);
  const [saleAssumptions, setSaleAssumptions] = useState(null);
  const [overallReturns, setOverallReturns] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [metricsForHoldPeriod, setMetricsForHoldPeriod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authPopupVisible, setAuthPopupVisible] = useState(false);

  const [subscriptionPopup, setSubscriptionPopup] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;

  useEffect(() => {
    //console.log("Location State:", locationState);

    if (locationState) {
      // Extract data from locationState and set your state variables
      const {
        propertyData,
      } = locationState;

      //console.log("Year 1 metrics:", propertyData.year1Metrics);

      // Set your state variables with the extracted data
      setPropertyData(propertyData);
      setYear1Metrics(propertyData.year_1_metrics);
      setMetric_display_names(propertyData.metric_display_names);
      setSaleAssumptions(propertyData.sale_assumptions);
      setOverallReturns(propertyData.overall_returns);
      setMetricsForHoldPeriod(propertyData.metrics_for_hold_period);
      setSummaryData(propertyData.summary);
    }
  }, [locationState]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyA2eyyqOgld5M3WUmtPinaYrCW5GeKpZxI",
      libraries: ["places"],
    });

    loader.load().then(() => {
      setScriptLoaded(true); // This will re-render the component
    });
  }, []);

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      //const latLng = await getLatLng(results[0]);

      // Extract address components
      const addressComponents = results[0].address_components;

      // Initialize variables to store address parts
      let streetNumber = '';
      let streetName = '';
      let city = '';
      let zipcode = '';
      let stateCode = '';
      let countyName = '';

      // Find and set the values for each address part
      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) {
          streetNumber = component.long_name;
        } else if (types.includes('route')) {
          streetName = component.long_name;
        } else if (types.includes('locality')) {
          city = component.long_name;
        } else if (types.includes('postal_code')) {
          zipcode = component.long_name;
        } else if (types.includes('administrative_area_level_1')) {
          stateCode = component.short_name;
        } else if (types.includes('administrative_area_level_2')) {
          countyName = component.long_name;
        }
        // Add more conditions if needed for other components
      });

      // Combine street number and name
      const street = `${streetNumber} ${streetName}`;

      // Update state variables with the extracted address parts
      setStreet(street);
      setCity(city);
      setZipcode(zipcode);
      setStateCode(stateCode);
      setCountyName(countyName);

      // Additional state updates if needed
      // setNumBedrooms(newValue);
      // setNumBathrooms(newValue);
      // setPurchasePrice(newValue);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const handleSubscriptionClick = () => {
    navigate("/profile");
  };

  const checkSubscriptionStatus = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const subscriptionsRef = collection(userDocRef, "subscriptions");
      const q = query(
        subscriptionsRef,
        where("status", "in", ["trialing", "active"])
      );

      const snapshot = await getDocs(q);

      // In this implementation, we only expect one active or trialing subscription to exist.
      if (!snapshot.empty) {
        const doc = snapshot.docs[0];
        //console.log(doc.id, " => ", doc.data());
        return doc.data().status; // Return the subscription status
      } else {
        // No active or trialing subscriptions found
        return "inactive"; // Return 'inactive' status
      }
    } catch (error) {
      console.error("Error checking subscription status:", error);
      throw error; // Throw the error for the caller to handle
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const currentUser = auth.currentUser;

      if (currentUser) {
        const userDocRef = doc(collection(db, "users"), currentUser.uid);

        const subscriptionStatus = await checkSubscriptionStatus(currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);
        const userData = userDocSnapshot.data();

        // if (userData.freeSearches > 0 || subscriptionStatus === "active") {
          const propertyData = {
            street,
            city,
            zipcode,
            state_code: stateCode,
            county_name: countyName,
            num_bedrooms: numBedrooms,
            num_bathrooms: numBathrooms,
            purchase_price: purchasePrice,
            factor: factor,
          };

          const response = await fetch(
            "https://call-flask-route-lon4yge32a-uc.a.run.app/create_property",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(propertyData),
            }
          );

          const data = await response.json();

          if (response.ok) {
            // Update freeSearches only when the API call is successful
            // if (userData.freeSearches > 0 && subscriptionStatus !== "active") {
            //   const updatedFreeSearches = userData.freeSearches - 1;
            //   await updateDoc(userDocRef, { freeSearches: updatedFreeSearches });
            // }

            // Update the state with the received property data
            setPropertyData(data.property);
            setYear1Metrics(data.year_1_metrics);
            setMetric_display_names(data.metric_display_names);
            setSaleAssumptions(data.sale_assumptions);
            setOverallReturns(data.overall_returns);
            setSummaryData(data.summary);
            setMetricsForHoldPeriod(data.metrics_for_hold_period);

            toast.success("Property analyzed successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
            });
          } else {
            const errorMessage = data.error || "An unknown error occurred.";

            if (
              errorMessage.includes(
                "cannot access local variable 'property_crime_grade' where it is not associated with a value"
              )
            ) {
              toast.error("Invalid ZIP code. Please check the provided ZIP code and try again.", {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error(errorMessage, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        // } else {
        //   setSubscriptionPopup(true);
        // }
      } else {
        // currentUser is null, show the login/signup popup
        setAuthPopupVisible(true);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        autoClose: 5000,
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <div className="property-form">
      <h1 className="form-title">SFR Analyzer</h1>
      <h2 className="form-subtitle">
        See how much you can make from a Single Family Rental <br></br>
        Use HUD Rent to see how much you can make leasing to Section 8{" "}
      </h2>
      <div className="row g-5 justify-content-center">
        <div className="col-lg-10 col-md-12 wow fadeIn" data-wow-delay="0.1s">
          <form onSubmit={handleSubmit} className="row g-3">
            <div className="col-sm-6">
              <div className={`form-floating address-input-container ${isInputFocused || street ? 'input-focused' : ''}`}>

                {scriptLoaded ? (
                  <PlacesAutocomplete
                    value={street}
                    onChange={setStreet}
                    onSelect={handleSelect}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <div className="input-container">
                          <label
                            className={`label-floating ${isInputFocused || street ? 'input-focused' : ''}`}
                            htmlFor="street"
                          >
                            Street:
                          </label>
                          <input
                            {...getInputProps({
                              className: 'form-control',
                              placeholder: '123 Test Drive',
                              id: 'street',
                              required: true,
                              onFocus: () => setIsInputFocused(true),
                              onBlur: () => setIsInputFocused(false),
                            })}
                          />
                        </div>
                        <div className="suggestions-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => (
                            <div
                              {...getSuggestionItemProps(suggestion)}
                              className="suggestion-item"
                              key={suggestion.placeId} // Ensure a key for list items for React's reconciliation
                            >
                              {suggestion.description}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                ) : (
                  <div className="input-container">
                    <label
                      className={`label-floating ${isInputFocused || street ? 'input-focused' : ''}`}
                      htmlFor="street"
                    >
                      Street:
                    </label>
                    <input
                      className='form-control'
                      placeholder='123 Test Drive'
                      id='street'
                      required={true}
                      value={street} // Make sure to bind the input's value to your state
                      onChange={e => setStreet(e.target.value)} // Update state on change
                      onFocus={() => setIsInputFocused(true)}
                      onBlur={() => setIsInputFocused(false)}
                    />
                  </div>
                )}
              </div>

            </div>
            <div className="col-sm-6">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                  placeholder="Analyzer City  "
                />
                <label htmlFor="city">City:</label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="zipcode"
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                  required
                  placeholder="12345  "
                />
                <label htmlFor="zipcode">Zipcode:</label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="stateCode"
                  value={stateCode}
                  onChange={(e) => setStateCode(e.target.value)}
                  maxLength={2}
                  required
                  placeholder="AL  "
                />
                <label htmlFor="stateCode">State Code:</label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="countyName"
                  value={countyName}
                  onChange={(e) => setCountyName(e.target.value)}
                  required
                  placeholder="Example County  "
                />
                <label htmlFor="countyName">County:</label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="numBedrooms"
                  value={numBedrooms}
                  onChange={(e) => setNumBedrooms(e.target.value)}
                  required
                  min="1"
                  placeholder="3  "
                />
                <label htmlFor="numBedrooms">Number of Bedrooms:</label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="numBathrooms"
                  value={numBathrooms}
                  onChange={(e) => setNumBathrooms(e.target.value)}
                  required
                  min="1"
                  placeholder="2  "
                  step="0.5"
                />
                <label htmlFor="numBathrooms">Number of Bathrooms:</label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="purchasePrice"
                  value={purchasePrice}
                  onChange={(e) => setPurchasePrice(e.target.value)}
                  required
                  min="10000"
                  placeholder="150000  "
                />
                <label htmlFor="purchasePrice">Purchase Price: $</label>
              </div>
            </div>

            <div className="mb-3"> {/* Added mb-3 for margin bottom */}
              <div className="text-center mb-2">Please select the house type:</div>
              <div className="d-flex justify-content-center flex-wrap"> {/* Ensures proper alignment and responsiveness */}
                <div className="form-check mx-2"> {/* mx-2 for some margin on the sides */}
                  <input
                    className="form-check-input"
                    type="radio"
                    name="houseType"
                    id="singleFamily"
                    value="1"
                    onChange={(e) => setFactor(e.target.value)}
                    required
                  />
                  <label className="form-check-label" htmlFor="singleFamily">
                    Single Family
                  </label>
                </div>
                <div className="form-check mx-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="houseType"
                    id="duplex"
                    value="2"
                    onChange={(e) => setFactor(e.target.value)}
                    required
                  />
                  <label className="form-check-label" htmlFor="duplex">
                    Two Family
                  </label>
                </div>
                <div className="form-check mx-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="houseType"
                    id="triplex"
                    value="3"
                    onChange={(e) => setFactor(e.target.value)}
                    required
                  />
                  <label className="form-check-label" htmlFor="triplex">
                    Three Family
                  </label>
                </div>
                <div className="form-check mx-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="houseType"
                    id="fourplex"
                    value="4"
                    onChange={(e) => setFactor(e.target.value)}
                    required
                  />
                  <label className="form-check-label" htmlFor="fourplex">
                    Four Family
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 text-center">
              <button type="submit" className="button">
                Analyze Property
              </button>
            </div>
          </form>
        </div>
      </div>


      {/* Conditional rendering based on loading state */}
      {loading ? (
        <LoadingSpinner /> // Display loading spinner while waiting
      ) : (
        // Render PropertyDetails component when propertyData is available
        propertyData && (
          <PropertyDetails
            property={propertyData}
            setProperty={setPropertyData}
            metricDisplayNames={metric_display_names}
            year1Metrics={year1Metrics}
            setYear1Metrics={setYear1Metrics}
            saleAssumptions={saleAssumptions}
            setSaleAssumptions={setSaleAssumptions} // Pass the set function
            overallReturns={overallReturns}
            setOverallReturns={setOverallReturns}
            summaryData={summaryData}
            setSummaryData={setSummaryData}
            metricsForHoldPeriod={metricsForHoldPeriod}
            setMetricsForHoldPeriod={setMetricsForHoldPeriod}
            scriptLoaded={scriptLoaded}
          />
        )
      )}

      {/* Render subscription pop-up based on subscriptionPopup state */}
      {subscriptionPopup && (
        <div className="subscription-popup">
          <div className="subscription-content">
            <h2>Out of Free Searches</h2>
            <p>
              You have used all your free searches. To continue using the SFR
              Analyzer, please subscribe to our service.
            </p>
            <button
              className="button"
              onClick={() => handleSubscriptionClick()}
            >
              Subscribe Now
            </button>
          </div>
        </div>
      )}

      {/* Render AuthPopup if user is not authorized */}
      {authPopupVisible && (
        <div className="auth-popup">
          <div className="auth-content">
            <h2>Analyzer Access</h2>
            <p>
              Login Or Sign Up To Get Free Searches Today
            </p>
            <button
              className="button"
              onClick={() => handleLogin()}
            >
              Login
            </button>
            <button
              className="button"
              onClick={() => handleSignUp()}
            >
              Sign Up
            </button>
          </div>
        </div>
      )}

      {/* <!-- disclaimer Start --> */}
      <div className="container-fluid disclaimer py-5">
        <div className="container pt-5">
          <div className="row gy-5 gx-0">
            <div className="col-lg-12 pe-lg-5 wow fadeIn" data-wow-delay="0.3s">
              <h1 className="display-6 mb-4">Disclaimer:</h1>
              <p className="text-black mb-2">
                Use of this calculator signifies your agreement to our Terms of
                Use and the terms posted below.
              </p>
              <p className="text-black mb-3">
                The Calculator is provided for informational purposes only and
                should not be considered as professional financial or real
                estate advice. We recommend that you seek the advice of a real
                estate professional before making any type of investment. SFR
                Analyzer is not responsible for the consequences of any
                decisions or actions taken in reliance upon or as a result of
                the information provided by this tool.
              </p>
              <p className="text-black mb-5">
                Furthermore, SFRAnalyzer uses third-party data to obtain
                information regarding neighborhood crime metrics and local
                market rents. SFR Analyzer is not responsible or liable for the
                accuracy of such data. We also pre-fill expense line items with
                national averages and loan assumptions. You should confirm the
                accuracy of these details by completing your own due diligence
                and reach out to loan brokers for updated loan pricing guidance.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- disclaimer End --> */}
    </div>
  );
};

export default PropertyForm;
