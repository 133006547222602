import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  addDoc,
  onSnapshot,
  collection,
  query,
  where,
} from "firebase/firestore";
import { db, auth, functions } from "./firebaseConfig";
import { httpsCallable } from 'firebase/functions';
import AppNavbar from "./Navbar";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./AboutUs.css";
import "./Profile.css";

const Profile = () => {
  const currentUser = auth.currentUser;
  const [userData, setUserData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const currentUrl = window.location.href;

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const subscriptionsRef = collection(userDocRef, 'subscriptions');
        const q = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

        const unsubscribe = onSnapshot(q, (snapshot) => {
          // In this implementation, we only expect one active or trialing subscription to exist.
          if (!snapshot.empty) {
            const doc = snapshot.docs[0];
            //console.log(doc.id, ' => ', doc.data());
            setSubscriptionData(doc.data());
            setIsSubscribed(true);
          } else {
            // No active or trialing subscriptions found
            setIsSubscribed(false);
          }
        });

        return () => {
          // Cleanup the listener when the component is unmounted
          unsubscribe();
        };
      } catch (error) {
        console.error("Error checking subscription status:", error);
        // Handle errors
      }
    };

    checkSubscriptionStatus();
  }, [currentUser.uid]);


  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            setUserData(userDocSnap.data());
          } else {
            //console.log("User document not found.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleSubscribe = async (priceId) => {
    try {
      toast.info("Generating Checkout link", { autoClose: false });

      const usersCollectionRef = collection(db, "users");
      const userDocRef = doc(usersCollectionRef, currentUser.uid);
      const checkoutSessionsCollectionRef = collection(
        userDocRef,
        "checkout_sessions"
      );

      const docRef = await addDoc(checkoutSessionsCollectionRef, {
        price: priceId,
        allow_promotion_codes: true,
        success_url: currentUrl,
        cancel_url: currentUrl,
      });

      let timeoutId = null;
      const unsubscribe = onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          toast.error(`An error occurred: ${error.message}`);
          clearTimeout(timeoutId);
        }
        if (url) {
          window.location.assign(url);
          toast.dismiss();
          clearTimeout(timeoutId);
        }
      });

      // Set a timeout to handle cases where checkout link is not generated
      timeoutId = setTimeout(() => {
        unsubscribe();
        toast.error("Failed to generate checkout link. Please try again.");
      }, 15000); // 15 seconds timeout

      return () => {
        unsubscribe();
        clearTimeout(timeoutId);
      };
    } catch (error) {
      console.error("Error creating CheckoutSession:", error);
      toast.error("An error occurred while initiating subscription.");
      toast.dismiss();
    }
  };

  const handleManageSubscription = async () => {
    try {
      const createPortalLink = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");

      const { data } = await createPortalLink({
        returnUrl: currentUrl, // Make sure 'currentUrl' is defined
      });

      window.location.assign(data.url);
    } catch (error) {
      console.error("Error creating portal link:", error);
    }
  };

  return (
    <div>
      <AppNavbar />
      <ToastContainer />
      <div className="profile-container">
        {userData ? (
          <div className="profile-info">
            <h2 className="title">Profile</h2>
            <p>Name: {userData.displayName}</p>
            <p>Email: {userData.email}</p>
            <p>Free Searches: {userData.freeSearches}</p>
            <p>Subscription Status: {subscriptionData ? subscriptionData.status : "N/A"}</p>
            {isSubscribed ? (
              <div className="subscription-buttons">
                <button className="button" onClick={handleManageSubscription}>
                  Manage Subscription
                </button>
              </div>
            ) : (
              <div className="subscription-buttons">
                <h3 className="subscribe-title">Pricing Options</h3>
                <button className="button" onClick={() => handleSubscribe("price_1OZF7ZGew3FQ47oNXrFwLQIK")}>
                  $14.99/month
                </button>
                <button className="button" onClick={() => handleSubscribe("price_1OZ0qJGew3FQ47oNZoa4lxSg")} style={{ marginLeft: '10px' }}>
                  $149.99/year
                </button>
              </div>
            )}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Profile;
