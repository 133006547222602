import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"; // Import for the Cloud Functions Emulator
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA2eyyqOgld5M3WUmtPinaYrCW5GeKpZxI",
  authDomain: "reip-eb47f.firebaseapp.com",
  databaseURL: "https://reip-eb47f-default-rtdb.firebaseio.com",
  projectId: "reip-eb47f",
  storageBucket: "reip-eb47f.appspot.com",
  messagingSenderId: "937861277444",
  appId: "1:937861277444:web:c3f4cdff12aed975c2d251",
  measurementId: "G-JHEQXFM4XL"
};

// Initialize App
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
// Create a Auth instance
const auth = getAuth(app);
// Create a Functions instance
const functions = getFunctions(app);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);
 
if (process.env.NODE_ENV === "development") {
  // Connect to Firestore and Auth Emulator (for development)
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");

  // Connect to Cloud Functions Emulator (for development)
  connectFunctionsEmulator(functions, "localhost", 5001);
}


export { app, db, auth, functions , analytics}; 
