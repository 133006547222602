import React, { useState, useEffect } from "react";
import "./HoldPeriodTable.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const HoldPeriodTable = ({ metricsForHoldPeriod, holdPeriod }) => {
  // State to store the extracted integer value of hold period
  const [holdPeriodValue, setHoldPeriodValue] = useState(null);

  useEffect(() => {
    // Extract numeric value from the holdPeriod string and convert it to an integer
    const extractedHoldPeriodValue = parseInt(
      holdPeriod.replace(/\D/g, ""),
      10
    );
    setHoldPeriodValue(extractedHoldPeriodValue);
  }, [holdPeriod]); // useEffect will re-run whenever holdPeriod changes

  // Check if holdPeriod is passed as a prop
  if (!holdPeriod) {
    // holdPeriod is not passed, display a message
    return <div>No hold period specified.</div>;
  }

  // Check if metricsForHoldPeriod is an object with 'Unlevered' and 'Levered' categories
  if (
    !metricsForHoldPeriod ||
    typeof metricsForHoldPeriod !== "object" ||
    !metricsForHoldPeriod.Unlevered ||
    !metricsForHoldPeriod.Levered
  ) {
    // Metrics data is not valid, display a message
    return <div>No Hold Period data available.</div>;
  }

  // holdPeriod is passed and metrics data is valid, continue rendering the table
  const years = Array.from({ length: holdPeriodValue }, (_, index) => index);

  return (
    <div className="hold-period-table">
      <h2 className="table-title">{holdPeriodValue} Year Returns Summary</h2>
      <table>
        <thead>
          <tr>
            <th>Year</th>
            {years.map((year) => (
              <th key={year}>Year {year + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Without the use of a loan</Tooltip>}
            >
              <th className="unlevered">Unlevered</th>
            </OverlayTrigger>
            {years.map((year) => (
              <td key={year}></td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>Total income generated from the property</Tooltip>
              }
            >
              <th>Revenue</th>
            </OverlayTrigger>
            {years.map((year) => (
              <td key={year}>{metricsForHoldPeriod.Unlevered.Revenue[year]}</td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  These are the costs incurred from owning and operating the
                  property.
                </Tooltip>
              }
            >
              <th>Expense</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}>
                -{metricsForHoldPeriod.Unlevered.Expense[year]}
              </td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Net Operating Income</Tooltip>}
            >
              <th>Net Operating Income</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}>
                {metricsForHoldPeriod.Unlevered["Net Operating Income"][year]}
              </td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Proceeds From Sale</Tooltip>}
            >
              <th>Proceeds From Sale</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}>
                {metricsForHoldPeriod.Unlevered["Proceeds From Sale"][year] ===
                "$0.00"
                  ? "-"
                  : metricsForHoldPeriod.Unlevered["Proceeds From Sale"][year]}
              </td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Total cashflow generated considering rental revenue and proceeds from sale. 
                </Tooltip>
              }
            >
              <th>Cashflow</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}>
                {metricsForHoldPeriod.Unlevered.Cashflow[year]}
              </td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                 Returns generated over a year. It is calculated as a percentage of the initial amount of investment.
                </Tooltip>
              }
            >
              <th>Annual Return</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}>
                {metricsForHoldPeriod.Unlevered["Annual Return"][year]}
              </td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>With the use of a loan</Tooltip>}
            >
              <th className="levered">Levered</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}></td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>Total income generated from the property</Tooltip>
              }
            >
              <th>Revenue</th>
            </OverlayTrigger>
            {years.map((year) => (
              <td key={year}>{metricsForHoldPeriod.Levered.Revenue[year]}</td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  These are the costs incurred from owning and operating the
                  property.
                </Tooltip>
              }
            >
              <th>Expense</th>
            </OverlayTrigger>
            {years.map((year) => (
              <td key={year}>-{metricsForHoldPeriod.Levered.Expense[year]}</td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>Payment made towards repaying the loan</Tooltip>
              }
            >
              <th>Mortgage</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}>-{metricsForHoldPeriod.Levered.Mortgage[year]}</td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Cash generated not considering sale of property</Tooltip>}
            >
              <th>Rental Cashflow</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}>
                {metricsForHoldPeriod.Levered["Rental Cashflow"][year]}
              </td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Amount paid to satisfy the loan and completely pay off the
                  debt.
                </Tooltip>
              }
            >
              <th>Loan Payoff</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}>
                -
                {metricsForHoldPeriod.Levered["Loan Payoff"][year] === "$0.00"
                  ? ""
                  : metricsForHoldPeriod.Levered["Loan Payoff"][year]}
              </td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Proceeds From Sale</Tooltip>}
            >
              <th>Proceeds From Sale</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}>
                {metricsForHoldPeriod.Levered["Proceeds From Sale"][year] ===
                "$0.00"
                  ? "-"
                  : metricsForHoldPeriod.Levered["Proceeds From Sale"][year]}
              </td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>How much of the property you own.</Tooltip>}
            >
              <th>Equity Accumulated</th>
            </OverlayTrigger>

            {years.map((year) => (
              <td key={year}>
                {metricsForHoldPeriod.Levered["Equity Accumulated"][year]}
              </td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Total cashflow generated considering rental revenue and proceeds from sale. 
                </Tooltip>
              }
            >
              <th>Cashflow</th>
            </OverlayTrigger>
            {years.map((year) => (
              <td key={year}>{metricsForHoldPeriod.Levered.Cashflow[year]}</td>
            ))}
          </tr>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Returns generated over a year. It is calculated as a percentage of the initial amount of investment.
                </Tooltip>
              }
            >
              <th>Annual Return</th>
            </OverlayTrigger>
            {years.map((year) => (
              <td key={year}>
                {metricsForHoldPeriod.Levered["Annual Return"][year]}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HoldPeriodTable;
