import React from "react";
import "./AboutUs.css";
import "./HowToUse.css";
import AppNavbar from "./Navbar";

const HowToUse = () => {
  return (
    <div>
      <AppNavbar />
      <div className="how-to-use-container">
        <div className="how-to-use-info">
          <h1 className="title">Welcome to SFR Analyzer!</h1>
          <p>
            Looking to get started investing in real estate? Welcome to SFR
            Analyzer! This guide will walk you through how to effectively use
            our app to analyze properties and make informed investment
            decisions.
          </p>

          {/* YouTube Video Embed */}
          <div className="video-container" style={{ position: 'relative', paddingBottom: '56.25%', /* 16:9 Aspect Ratio */ height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
            <iframe
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              width="560"
              height="315"
              src="https://www.youtube.com/embed/8MKUTNMIyok?si=RZdovMwWAUPSq2LW"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <h2>Getting Started</h2>
          <p>
            <strong>Sign Up/Log In:</strong> To begin using our app, you'll need
            to create an account or log in if you already have one.
          </p>

          <h2>Input Data</h2>
          <h4>Entering Property Details:</h4>
          <p>
            Enter the property's address, the number of bedrooms, and the number
            of bathrooms. This information is essential for our analysis.
          </p>

          <h2>Property Analysis</h2>
          <h4>Market Rent and Government Assistance:</h4>
          <p>
            After entering the property details, you'll instantly see both the
            market rent for the unit and the HUD Rent which is the amount that
            can be covered by the Section 8 Housing Choice Voucher Program. (We
            included this as we want to encourage investors to consider
            investing in this program). This rental information helps you gauge
            the property's income potential.
          </p>
          <h4>Neighborhood Information:</h4>
          <p>
            Our app provides valuable insights into the neighborhood's safety by
            displaying violent and property crime grades. This information is
            crucial for evaluating the property's location.
          </p>
          <h4>Expense Line Items:</h4>
          <p>
            Our app uses generalized formulae to estimate expenses. These
            calculations are based on national averages and as such may not be
            accurate for your specific property. Be sure to review this section
            to ensure accuracy.
          </p>

          <h2>Cashflow Analysis</h2>
          <h4>Income and Expenses:</h4>
          <p>
            We calculate the property's income and expenses to help you
            determine its profitability.
          </p>
          <h4>Hold Period Return Summary:</h4>
          <p>
            You'll also see a projection of the property's returns over the hold
            period. This quantitative tool allows you to assess the long-term
            viability of your investment.
          </p>

          <h2>Making Informed Decisions</h2>
          <h4>Investment Decisions:</h4>
          <p>
            We provide an investment summary and ratings to help you make sense
            of the numbers. Armed with the data from our app, you can make
            informed investment decisions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToUse;
