import React, { useEffect, useRef, useState } from 'react';

const GoogleMap = ({ address }) => {
  const [coordinates, setCoordinates] = useState(null);
  const [loading, setLoading] = useState(true);
  const mapRef = useRef(null); // Reference to the div where the map will be displayed

  useEffect(() => {
    const geocodeAddress = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=AIzaSyA2eyyqOgld5M3WUmtPinaYrCW5GeKpZxI`
        );

        const data = await response.json();

        if (data.results && data.results.length > 0) {
          const location = data.results[0].geometry.location;
          setCoordinates({ latitude: location.lat, longitude: location.lng });
        } else {
          setCoordinates(null);
        }
      } catch (error) {
        console.error('Error geocoding address:', error);
        setCoordinates(null);
      } finally {
        setLoading(false);
      }
    };

    geocodeAddress();
  }, [address]);

  useEffect(() => {
    if (!loading && coordinates) {
      // Ensure the Google Maps API script is loaded before trying to use `google.maps`
      if (window.google && window.google.maps) {
        // Initialize the map
        const map = new window.google.maps.Map(mapRef.current, {
          center: { lat: coordinates.latitude, lng: coordinates.longitude },
          zoom: 15,
        });

        // Add a marker at the geocoded address
        new window.google.maps.Marker({
          position: { lat: coordinates.latitude, lng: coordinates.longitude },
          map: map,
        });
      }
    }
  }, [loading, coordinates]); // Re-run this effect if loading state or coordinates change

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!coordinates) {
    return <div>Map data not available</div>;
  }

  // Container for the map, ref is attached to this div
  return <div ref={mapRef} style={{ width: '100%', height: '400px' }} />;
};

export default GoogleMap;
