import React, { useState } from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import Logo from "./logo.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Navbar.css";

const AppNavbar = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  // Check user authentication state
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }
  });

  const handleSignOut = async () => {
    try {
      await signOut(getAuth());
      //console.log("User signed out");

      toast.success("Signed out successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const isUserValid = null !== user; // Check if there is a valid user

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" style={{ height: "70px" }}>
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={Logo}
              alt="Your Logo"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            SFR Analyzer
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {isUserValid && (
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/aboutus" className="text-white">
                  About Us
                </Nav.Link>
                <Nav.Link as={Link} to="/profile" className="text-white">
                  Profile
                </Nav.Link>
                <Nav.Link as={Link} to="/howtouse" className="text-white">
                  How to Use
                </Nav.Link>
                <Nav.Link as={Link} to="/savedproperties" className="text-white">
                  Saved Properties
                </Nav.Link>
                <Nav.Link as={Link} to="/feedback" className="text-white">
                  Feedback
                </Nav.Link>
              </Nav>
            )}
            <Nav className="ms-auto me-0">
              {isUserValid ? (
                <Button
                  variant="outline-danger"
                  onClick={handleSignOut}
                  style={{ color: "#b9fa3d", border: "1px solid #b9fa3d" }}
                >
                  Sign Out
                </Button>
              ) : (
                <>
                  <Button variant="outline-success" as={Link} to="/signup" className="mt-2 mr-2">
                    Sign Up
                  </Button>
                  <Button
                    variant="success"
                    as={Link}
                    to="/login"
                    className="mt-2 mr-2"
                  >
                    Login
                  </Button>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AppNavbar;
