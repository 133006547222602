import React from "react";
import PropertyForm from "./PropertyForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppNavbar from "./Navbar";

function App() {
  return (
    <div className="App">
      <AppNavbar />
      <PropertyForm />
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
}

export default App;
