import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";


//import { getAnalytics } from "firebase/analytics";
import {
  onAuthStateChanged,
} from "firebase/auth";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"; // Import Navigate

import AboutUs from "./AboutUs";
import Signup from "./Signup";
import Login from "./Login";
import Profile from "./Profile";
import HowToUse from "./HowToUse";
import Feedback from "./Feedback";
import SavedProperties from "./SavedProperties";

import { auth } from "./firebaseConfig"; // Import the Firebase configuration

const ProtectedAppRoute = ({ element }) => {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true); // Add loading state

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false); // Mark loading as done
    });

    return unsubscribe;
  }, []);

  if (loading) {
    // Show loading indicator or placeholder
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return element;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/aboutus"
        element={<AboutUs />}
      />
      <Route
        path="/profile"
        element={<ProtectedAppRoute element={<Profile />} />}
      />
      <Route
        path="/howtouse"
        element={<ProtectedAppRoute element={<HowToUse />} />}
      />
      <Route 
        path="/savedproperties" 
        element={<SavedProperties />} 
      />
      <Route
        path="/feedback"
        element={<ProtectedAppRoute element={<Feedback />} />}
      />
    </Routes>
  </Router>
);

reportWebVitals();