import React from "react";
import "react-toastify/dist/ReactToastify.css";
import AppNavbar from "./Navbar";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="AboutUs">
      <AppNavbar />
      <div className="content">
        <div className="intro">
          <div className="intro-content">
            <h1 className="welcome-title">SFR Analyzer</h1>
            <p className="description">Identify great real estate investment opportunities in less than a minute. </p>
          </div>
          <div className="big-picture-container">
            {/* Use an empty alt attribute for decorative images */}
            <img src="/intro.png" alt="" className="big-picture" />
          </div>
        </div>
        {/* About Section */}
        <div className="about">
          <div className="about-image-container">
            <img src="/about.png" alt="" className="about-image" />
          </div>
          <div className="about-content">
            <h2 className="about-title">Looking to get started investing in Real Estate? </h2>
            <p>
              SFR helps you identify solid real estate investment opportunities so that you can build your rental portfolio and generate passive income. Input property data to generate potential returns and receive SFR Expert Ratings. Use HUD rent to see how much you can make renting to Section 8.
            </p>
            <h3>SFR Analyzer Features:</h3>
            <ul className="feature-list">
              <li>Real-Time Rental Rates</li>
              <li>Crime Data</li>
              <li>Rental Calculator</li>
              <li>SFR Investment Ratings</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
