import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";
import AppNavbar from "./Navbar";
import "./AboutUs.css";
import "./Feedback.css";

const Feedback = () => {
  const [feedback, setFeedback] = useState({
    comments: "",
    rating: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFeedback({ ...feedback, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const feedbackRef = collection(db, "feedback");
      await addDoc(feedbackRef, feedback);
      console.log("Feedback submitted successfully:", feedback);
      // Optionally, reset the form after submission
      setFeedback({ comments: "", rating: "" });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      // Handle the error (e.g., show a user-friendly message)
    }
  };

  return (
    <div>
      <AppNavbar />
      <div className="feedback">
        <h2 className="title">Give Us Your Feedback</h2>
        <form onSubmit={handleSubmit}>
          {/* Input fields for comments and rating */}
          <div>
            <label htmlFor="comments">Comments</label>
            <textarea
              id="comments"
              name="comments"
              value={feedback.comments}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label htmlFor="rating">Rating (1-5) stars</label>
            <input
              type="number"
              id="rating"
              name="rating"
              value={feedback.rating}
              onChange={handleInputChange}
              min="1"
              max="5"
              required
            />
          </div>

          <button type="submit" className="button">
            Submit Feedback
          </button>
        </form>
      </div>
    </div>
  );
};

export default Feedback;
